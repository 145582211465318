.events__list-item {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #5101d1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.events__list-item h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #5101d1;
}

.events__list-item h2 {
    margin: 0;
    font-size: 1rem;
    color: #7c7c7c;
}

.events__list-item p {
    margin: 0;
}