.hero2 {
    height: 100vh; /* Make the hero section cover the full height of the screen */
    display: flex; /* Use flexbox to center the content vertically */
    justify-content: center; /* Center the content vertically */
    align-items: center; /* Center the content horizontally */
    text-align: center;
    background-color: #f4f4f4;background-image: url('../Home/hero-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

.hero2-content h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    position: relative;
}

.hero2-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.hero2-content button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #333;
    border: none;
    cursor: pointer;
}

