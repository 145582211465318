body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.form-control label,
.form-control input,
.form-control textarea {
    width: 100%;
    display: block;
}

.form-control label {
    margin-bottom: 0.5rem;
}

.form-control {
    margin-bottom: 1rem;
}

.form-actions button,
.btn {
    background: #c21881;
    font: inherit;
    border: 1p solid #7837b4;
    border-radius: 3px;
    padding: 0.25rem 1rem;
    margin-right: 1rem;
    box-shadow: 1px 1px 5px rgba(red, green, blue, alpha);
    color:#000;
    cursor: pointer;
}

.form-actions button:hover,
.form-actions button:active,
.btn:hover,
.btn:active {
    background: rgb(22, 7, 7);
    border-color: red;
    color:white;
}