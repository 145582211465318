/* Home.css */

/* Style for the hero section */
/* Style for the hero section */
.hero {
    background-image: url('./hero-bg.jpg');
    height: 100vh; /* Full viewport height */
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2em;
  }
  
  /* Style for the slider */
  .slider {
    height: 200px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }
  
  /* Style for the two column layout */
  .row {
    display: flex;
    justify-content: space-between;
  }
  
  .col {
    width: 45%;
    background-color: #d0d0d0;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
  }
  
  /* Home.css */

/* Style for the body and html */
body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  
  /* Style for the Home component */
  .Home {
    flex: 1 0 auto;
  }
  
  /* Style for the footer */
  .footer {
    height: 100px;
    background-color: #c0c0c0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    flex-shrink: 0;
  }

  /* Hero.css */

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  position: relative; /* Relative to the hero section */
}

h1, .btn-primary {
  position: absolute; /* Absolute position */
  z-index: 1; /* Above the ElectrifiedNet component */
  top: 50%;
  text-align: center;
}

/* .hero-btn {
  top: 90%;
} */