.main-navigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 3.5rem;
    background:#f761ef;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    z-index: 10;
    opacity: 0.5;
}
.main-navigation:hover {
    opacity: 1;
}
.main-navigation-logo h1 {
    margin: 0;
    font-size: 1.5rem;
}
.main-navigation__items {
    margin: 0 0 0 1.5rem;
    padding: 0;
    list-style: none;
}
.main-navigation__items ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
}
.main-navigation__items li {
    margin: 0 0.5rem;
}
.main-navigation__items a,
.main-navigation__items button {
    text-decoration: none;
    color: #140514;
    padding: 0.25rem 0.5rem;
    border:none;
    font: inherit;
    background: transparent;
    cursor: pointer;
    margin:0;
}
.main-navigation__items a:hover,
.main-navigation__items a:active,
.main-navigation__items a.active,
.main-navigation__items button:hover,
.main-navigation__items button:active {
    background: #ffffff;
    color:#5101d1;
    border-radius: 5px;
}

.main-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .main-navigation__items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .main-navigation__items li {
    margin-right: 1rem;
  }

  body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .main-navigation-logo {
    order: 2;
    position: absolute;
    right: 0px;
    top: -12px;
    z-index: 1;
  }

  .main-navigation-logo img {
    width: 100px;
    border-radius: 5px;
  }
  
    
  .main-navigation {
    order: 1;
  }
  
  .main-navigation__items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .main-navigation__items li {
    margin-right: 1rem;
  }

  .hide {
    display: none;
  }