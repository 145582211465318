.slider {
    /* Create a blue gradient background */
    background: linear-gradient(180deg, #0000ff, #add8e6);
    /* Set the background size to twice the size of the Slider section to create a moving effect */
    background-size: 200% 200%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../Home/hero-bg.jpg');
    /* Create an animation named sparkle that lasts 5 seconds and repeats indefinitely */
    animation: sparkle 5s infinite;
  }
  
  /* Define the sparkle animation */
  @keyframes sparkle {
    0% {
      /* At the start of the animation, the background position is at the top left corner */
      background-position: 0% 0%;
    }
    50% {
      /* At the middle of the animation, the background position is at the bottom right corner, creating a sparkling effect */
      background-position: 100% 100%;
    }
    100% {
      /* At the end of the animation, the background position is back at the top left corner */
      background-position: 0% 0%;
    }
  }


  .slider button {
    /* Style the button */
    background-color: #0000ff; /* Blue background */
    color: white; /* White text */
    padding: 10px 20px; /* Padding */
    border: none; /* No border */
    cursor: pointer; /* Cursor pointer */
    text-decoration: none; /* No underline */
    transition: background 0.3s ease; /* Transition effect */
    animation: buttonAnimation 1s infinite;
  }
  
  .slider button:hover {
    /* Change the background color when the button is hovered */
    background-color: #add8e6;
  }
  
  /* Define the animation */
  @keyframes buttonAnimation {
    0% {
      background-color: #0000ff;
    }
    50% {
      background-color: #add8e6;
    }
    100% {
      background-color: #0000ff;
    }
  }
  
  .slider button:active {
    /* Add the animation when the button is active */
    animation: buttonAnimation 1s infinite;
  }